export enum Area {
  AREA = 1,
  PROVINCE = 2,
  DISTRICT = 3,
  WARN = 4,
}

export enum Status {
  ACTIVED = 1,
  DISABLED = 0,
}

export enum Languages {
  VN = 'vi',
  EN = 'en',
}

export enum LabelPosition {
  TOP_LEFT = 'topLeft',
  TOP_MID = 'topMid',
  TOP_RIGHT = 'topRight',
  MID_LEFT = 'midLeft',
  MID_RIGHT = 'midRight',
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM_MID = 'bottomMid',
  BOTTOM_RIGHT = 'bottomRight',
}

export enum Platform {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
}

export enum SaleChannel {
  B2C = 'b2c',
  OL01 = 'OL01',
  B2E = 'b2e',
  B2B = 'b2b',
}

// Default Miền Bắc
export const DEFAULT_REGION = 'MB'

export enum MenuTypes {
  GLOBAL_TOP = 'global_top',
  GLOBAL_QUICK = 'quick_menu',
  GLOBAL_BOTTOM = 'global_bottom',
  HOME_PRODUCT_CAT = 'home_product_cat',
}
export enum PromotionTypes {
  PROMOTION = 'promotion',
  TRENDING = 'trending',
  BEST_SELLER = 'bestSeller',
  PROMOTION_PAGE = 'promotionPage',
  CMS_PAGE = 'cmsPage',
  YOUR_PROMOTION = 'homepageYourPromotion',
  LIST_HOT = 'listHot',
}

export enum MenuType {
  PRODUCT = 'product',
  LINK = 'link',
  PRODUCT_CAT = 'productCat',
}

export enum ProductStockStatus {
  OUT_OF_STOCK = 'outOfStock',
  ARRIVING = 'arriving',
  OUT_OF_BUSINESS = 'outOfBusiness',
  INCOMING = 'incoming',
  IN_STOCK = 'inStock',
}

export enum BannerType {
  TOP_BAR = 'topBarBanner',
  PROMOTION = 'promotionBanner',
  SIDEBAR = 'sideBarBanner',
  MAIN = 'homeMainSlider',
  HOME = 'homeBlockBanner',
  HOME_FOOTER = 'homeFooterBanner',
  LOGIN = 'loginBanner',
  REGISTER = 'registerBanner',
  FULL_POPUP = 'fullPopup',
  COUNTDOWN_POPUP = 'countdownPopup',
}
export enum MenuItemType {
  LINK = 'link',
  POST = 'post',
  POST_CAT = 'postCat',
  PRODUCT = 'product',
  PRODUCT_CAT = 'productCat',
  PAGE_CMS = 'pageCMS',
}
export enum PageType {
  PRODUCT = 'products',
  PAGE_CMS = 'pageCMS',
  POST = 'posts',
  CATEGORY = 'categories',
}

export enum PageTypeSuffix {
  PAGE_CMS = '.p',
  POST = '.b',
  CATEGORY = '.c',
}
export enum ProductCardType {
  TRENDING = 'trending',
  RELATED = 'related-product',
  RECOMMENDED = 'recommended',
  RESULT = 'result',
  FLASH_SALE = 'flashSale',
  PRODUCT_CARD = 'product-card',
  PRODUCT_DETAIL = 'product-detail',
  LIST_HOT = 'listHot',
  PRODUCT_LIST_CMS = 'productListCMS',
  PRODUCT_TAB_CMS = 'productTabCMS',
  PRODUCT_LIST_FULL_CMS = 'productListFullCMS',
  BUNDLED = 'bundled',
}

export enum ServiceType {
  SHIPPING = 'shipping',
  SHIPPING_AND_INSTALL = 'shippingAndInstall',
}

export const PHONE_REGEXP = new RegExp(
  /^(0|84)(2(0[3-9]|1[0-6|8|9]|2[0-2|5-9]|3[2-9]|4[0-9]|5[1|2|4-9]|6[0-3|9]|7[0-7]|8[0-9]|9[0-4|6|7|9])|3[2-9]|5[2|5|6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])([0-9]{7})$/
)

export enum AddToCartType {
  BUY_NOW = 'buy-now',
  ADD = 'add-to-cart',
  CONTACT = 'contact',
  OUT_OF_BUSINESS = 'outOfBusiness',
  INSTALLMENT = 'installment',
  PRE_ORDER = 'preOrder',
}
export const TIMES = [
  { label: '09:00 - 12:00', value: '09:00:00:000', hour: 12 },
  { label: '12:00 - 14:00', value: '12:00:00:000', hour: 14 },
  { label: '14:00 - 16:00', value: '14:00:00:000', hour: 16 },
  { label: '16:00 - 18:00', value: '16:00:00:000', hour: 18 },
  { label: '18:00 - 20:00', value: '18:00:00:000', hour: 20 },
]

export enum SHIPPING_METHOD {
  STANDARD = 'standard',
  PICKUP_IN_STORE = 'pickUpInStore',
}

export enum RadioGroupType {
  CHOOSE_PAYMENT = 'CHOOSE_PAYMENT',
  PAYMENT = 'PAYMENT',
}
export enum PaymentProviderType {
  ECOPAY = 'ECOPAY',
  ONEPAY = 'ONEPAY',
  TECHCOMBANK = 'TCB',
}
export enum PAYMENT_STATUS {
  WAIT_TO_PAY = 'waitToPay',
  PAID = 'paid',
  SUCCESS = 'success',
  FAILED = 'failed',
  TIMEOUT = 'timeout',
  CANCELLED = 'cancelled',
}

export enum OrderStatus {
  WAIT_TO_CONFIRM = 'waitToConfirm',
  CONFIRMED = 'confirmed',
  SHIPPING = 'shipping',
  SHIPPED = 'shipped',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  PARTIAL_SHIPPED = 'partialShipped',
  CANCELED_BY_USER = 'canceledByUser',
  REFUNDED = 'refunded',
  RETURNED = 'returned',
  DEBT_OVER_DUE = 'debtOverdue',
  PAID = 'paid',
  HANG_UP_PAYMENT = 'hangUpPayment',
  PROCESSING = 'processing',
}

export enum PAYMENT_METHODS {
  COD = 'COD',
  BANK_TRANSFER = 'bankTransfer',
  ATM = 'atm',
  INSTALLMENT = 'installment',
  QR_CODE = 'qrcode',
  CREDIT_CARD = 'creditCard',
}
export enum BANK_TYPES {
  DOMESTIC = 'domestic',
  INTERNATIONAL = 'international',
}

export enum StoreType {
  CENTER = 'center',
  SHOP = 'shop',
  WAREHOUSE = 'warehouse',
}
export enum API_STATUS_CODE {
  UNSUPPORTED = 'UNSUPPORTED',
  PRODUCT_OUT_OF_STOCK = 'PRODUCT_OUT_OF_STOCK',
  PRODUCT_LIMIT_SALE = 'PRODUCT_LIMIT_SALE',
}
export enum VoucherTypes {
  PERCENT = 'percent',
  FIXED = 'fixed',
}
export enum BLOCK_CMS_TYPE {
  BANNER_ONE_LINE = 'bannerOneLine',
  BANNER_22 = 'banner22',
  PRODUCT_LIST = 'productList',
  PRODUCT_TAB = 'tabProduct',
  SLIDER = 'bannerSlider',
  TAB_SCROLL = 'tabScroll',
  PRODUCT_LIST_FULL = 'productListFull',
}

export enum FlashSaleType {
  FLASH_SALE = 'flashSale',
  PROMOTION = 'promotion',
}
export enum DepositStatus {
  DEPOSITED = 'deposited',
  UN_DEPOSITED = 'unDeposited',
}
export enum CouponType {
  COUPON = 'coupon',
  PRODUCT = 'product',
  SERVICE = 'service',
}

export const PAYMENT_AUTHENTICATION_STATUS = {
  AUTHENTICATION_SUCCESSFUL: 'AUTHENTICATION_SUCCESSFUL',
}

export const PAYMENT_PA_STATUS = {
  NO_VERIFY: 'U',
}

export enum GiftProductType {
  GIFT = 'gift',
  DISCOUNT = 'discount',
}
